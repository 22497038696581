<template>
	<b-modal size="xl" ref="modalAvenant">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.creer_avenant') }}</h2>
		</template>
		<template slot="default">
			<div class="form-group">
				<label for="">{{ $t('monte.type_changement') }}</label>
				<e-select
					v-model="type_avenant"
					id="avenanttype_id"
					track-by="avenanttype_id"
					label="avenanttype_label"
					:placeholder="$t('monte.rechercher_avenant_type')"
					:selectedLabel="$t('global.selected_label')"
					:options="avenantsFormatted"
					:allow-empty="true"
					:show-labels="false"
					:loading="loadingAvenantType"
				></e-select>
			</div>
			
			<div class="form-group">
				<label for="">{{ $t('monte.avenant_date_changement') }}</label>
				<e-datepicker v-model="avenant_date"></e-datepicker>
			</div>

			<template v-if="type_avenant != null">

				<template v-if="(type_avenant.avenanttype_type == 'saison' || type_avenant.avenanttype_type == 'etalon' || type_avenant.avenanttype_type == 'type_monte') && !syndic_saillie">
					<Saison 
						ref="Saison"
						v-model="season"
						:season_id="season_id_local"
						:all_season="all_season"
					>
					</Saison>

					<Etalon 
						ref="Etalon"
						v-model="stallion"
						:stallion_id="stallion_id_local"
						:contractconfig_id.sync="contractconfig_id_local"
						:season.sync="season"
						:errors="errorsStallion"
					>
					</Etalon>

					<Modele 
						ref="Modele"
						v-model="modele"
						:contractconfig_id="contractconfig_id_local"
						:errors="errorsModele"
					></Modele>

					<TypeMonte 
						ref="TypeMonte"
						v-model="type_monte"
						:contractconfig_id="contractconfig_id_local"
						:avenant_typemonte="avenant_local.avenant_typemonte"
						:errors="errorsTypeMonte"
					></TypeMonte>

					<TableAvenantArticle
						ref="TableAvenantArticle"
						:tiers_tab="tiers_tab_local"
						:tiers="tiers_local"
						:typemonte="type_monte"
						:contractconfig_id="contractconfig_id_local"
						:avenant="avenant_local"
						:avenant_article.sync="avenant_article"
						:free="avenant.avenant_free"
					></TableAvenantArticle>
				</template>

				<div class="form-group" v-if="type_avenant.avenanttype_type == 'jument'">
					<label for="mare">{{ $t('monte.jument') }} *</label>
					<e-select
						v-model="mare"
						id="mare"
						track-by="horse_id"
						label="horse_nom"
						:placeholder="$t('monte.rechercher_mare')"
						:selectedLabel="$t('global.selected_label')"
						:options="list_mare"
						:allow-empty="false"
						:show-labels="false"
						:loading="loadingMare"
						:class="{ 'is-invalid': errorsMare && errorsMare.indexOf('mare') > -1 }"
					></e-select>
				</div>

				<div class="form-group" v-if="type_avenant.avenanttype_type == 'cmep'">
					<label for="cmep">{{ $t('monte.cmep') }} *</label>
					<e-select
						v-model="cmep"
						track-by="tiers_id"
						label="tiers_rs"
						:placeholder="$t('monte.rechercher_cmep')"
						:selectedLabel="$t('global.selected_label')"
						:options="tiers_tab_local"
						:allow-empty="true"
						:show-labels="false"
						:class="{ 'is-invalid': errorsCmep && errorsCmep.indexOf('cmep') > -1 }"
					></e-select>
				</div>

				<div class="form-group" v-if="type_avenant.avenanttype_type == 'annulation'">
					<b-form-checkbox
						v-model="solde_contract"
					>
						{{ $t('global.solde_contract') }}
					</b-form-checkbox>
				</div>

				<div class="form-group">
					<b-form-checkbox
						v-model="avenant_transfert"
						:value="1"
						:unchecked-value="0"
					>
						{{ $t('monte.transfert') }}
					</b-form-checkbox>
				</div>

				<div class="form-group" v-if="avenant_transfert">		
					<label for="porteuse">{{ $t('monte.porteuse') }}</label>
					<e-select
						v-model="porteuse"
						track-by="horse_id"
						label="horse_nom"
						:placeholder="$t('monte.rechercher_porteuse')"
						:selectedLabel="$t('global.selected_label')"
						:options="list_mare"
						:allow-empty="false"
						:show-labels="false"
						:loading="loadingMare"
						:class="{ 'is-invalid': errors && errors.indexOf('jument') > -1 }"
					></e-select>
				</div>
			</template>
			<ErrorAlert messageI18n="monte.fin_chargement" :class="{ 'd-none': !errorsContractConfig || errorsContractConfig.indexOf('contract_config') == -1 }"/>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.modifier') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import Gynecologie from "@/mixins/Gynecologie.js"
	import Tiers from "@/mixins/Tiers.js"
	import Syndic from "@/mixins/Syndic.js"
	
	import ContractCleaner from '@/assets/js/cache/cleaners/ContractCleaner'

	export default {
		name: 'ModalAddAvenant',
		mixins: [Navigation, Tools, ContractMixin, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Gynecologie, Tiers, Syndic],
		props: {
			season_id: {
				type: Number,
				default: () => ( 0 )
			},
			stallion_id: {
				type: Number,
				default: () => ( 0 )
			},
			season_choosen:{
				type: Object,
				default: () => ( {} )
			},
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			avenant_typemonte:{
				type: Number,
				default: () => ( 0 )
			},
			typemonte:{
				type: Object,
				default: () => ( null )
			},
			tiers_tab: {
				type: Array,
				default: () => ( [] )
			},
			tiers: {
				type: Object,
				default: () => ( null )
			},
			avenant: {
				type: Object,
				default: () => ( {} )
			},
			horse: {
				type: [Object, Array],
				default: () => ( {} )
			},
			is_cancel: {
				type: Boolean,
				default: () => ( false )
			},
			all_season: {
				type: Array,
				default: () => ( [] )
			},
			syndic_saillie: {
				type: Array,
				default: () => ( [] )
			},
			contract_id: {
				type: Number,
				default: () => ( 0 )
			}

		},
		data () {
			return {
				errors: [],
				errorsStallion: [],
				errorsModele: [],
				errorsTypeMonte: [],
				errorsMare: [],
				errorsCmep: [],
				errorsContractConfig: [],
				avenants: [],
				avenant_article: [],
				list_mare: [],
				processing: false,
				loadingAvenantType: false,
				loadingMare: false,
				formComplete: false,
				type_avenant: null,
				avenant_date: new Date(),
				avenant_transfert: 0,
				season: {},
				stallion: {},
				type_monte: {},
				modele: {},

				cmep: null,
				mare: null,
				season_id_local: null,
				stallion_id_local: null,
				contractconfig_id_local: null,
				avenant_typemonte_local: null,
				typemonte_local: null,
				tiers_tab_local: null,
				tiers_local: null,
				avenant_local: null,
				// syndic_saillie: null,
				porteuse: null,
				solde_contract: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				//Je mets toutes les props dans des datas locale
				this.season_id_local = this.season_id
				this.stallion_id_local = this.stallion_id
				this.season_choosen_local = this.season_choosen
				this.contractconfig_id_local = this.contractconfig_id
				this.avenant_typemonte_local = this.avenant_typemonte
				this.typemonte_local = this.typemonte
				this.tiers_tab_local = this.tiers_tab
				this.tiers_local = this.tiers
				this.avenant_local = this.avenant
				this.avenant_transfert = this.avenant_local.avenant_transfert

				this.avenants = await this.loadAvenantType()

				// if(this.stallion_id_local != "0") {
				// 	this.syndic_saillie = await this.loadHorseSyndicBySeason(this.stallion_id_local, this.season_id_local)
				// }

				if(this.tiers != undefined) {
					this.loadMare(this.tiers.tiers_id)
				}
			},

			openModal() {
				this.$refs.modalAvenant.show()
			},
			async checkForm(){
				if(!this.type_avenant) {
					return false;
				}

				this.processing = true

				let articles = []

				//Je vais faire des vérifs pour chaque type d'avenant
				if(this.type_avenant.avenanttype_type == 'saison' || this.type_avenant.avenanttype_type == 'etalon' || this.type_avenant.avenanttype_type == 'type_monte')
				{
					articles = this.avenant_article

					this.errors = []
					this.errorsStallion = []
					this.errorsModele = []
					this.errorsTypeMonte = []

					if(this.stallion == undefined)
					{
						this.errorsStallion.push("stallion")
						this.errors.push(true)
					}

					if(this.modele == null)
					{
						this.errorsModele.push("modele")
						this.errors.push(true)
					}

					if(this.type_monte == null)
					{
						this.errorsTypeMonte.push("type_monte")
						this.errors.push(true)
					}
				}
				else if(this.type_avenant.avenanttype_type == 'jument')
				{
					//

					if(this.mare == null)
					{

						this.errorsMare.push("mare")
						this.errors.push(true)
					}
				}
				else if(this.type_avenant.avenanttype_type == 'cmep')
				{
					//
					if(this.cmep == null)
					{

						this.errorsTypeMonte.push("cmep")
						this.errors.push(true)
					}
				}

				if(this.contractconfig_id_local == 0) {
					this.errorsContractConfig.push("contract_config")
					this.errors.push(true)
				}


				if(this.errors.length > 0){
					this.processing = false
					return false
				}

				if(!this.avenant_transfert) {
					this.porteuse = null
				}

				let infos = {
					contractconfig_id: this.contractconfig_id_local,
					season: this.season,
					stallion: this.stallion,
					modele: this.modele,
					type_monte: this.type_monte,
					cmep: this.cmep,
					mare: this.mare,
					porteuse: this.porteuse
				}

				let avenant_id = await this.createAvenantOnline(
					this.avenant.avenant_id,
					this.type_avenant.avenanttype_type,
					this.contract_id,
					this.contractconfig_id_local,
					infos,
					this.avenant_date,
					this.avenant_transfert
				)

				// await this.$sync.force(true, true)
				// avenant_id = this.$sync.replaceWithReplicated('contract_avenant', avenant_id)

				if(this.type_avenant.avenanttype_type != 'jument' && this.type_avenant.avenanttype_type != 'cmep' && this.type_avenant.avenanttype_type != 'annulation')
				{
					await this.saveAvenantArticlesV2(avenant_id, articles, this.contract_id)
				}

				if(this.type_avenant.avenanttype_type == 'annulation')
				{
					await this.freeSaillieContractv2(this.contract_id)
					if(this.solde_contract){
						await this.saveAvenantArticlesV2(avenant_id, [], this.contract_id) //Je supprime les articles à facturer
					}
				}
			
				if(this.type_avenant.avenanttype_type === "jument") {
					this.$router.push({ name: 'ContractMareList', params: { horse_id: this.mare.horse_id }})
				}

				// await this.$sync.force(true, true)
              	// ContractCleaner.inst().onMutation([this.contract_id], ['articles'])

				this.processing = false

				this.$emit("avenant_saved", true)
			},
			async loadMare(tiers_id)
			{
				if(tiers_id != undefined && tiers_id > 0)
				{
					this.list_mare = []
					let season_mare = await this.loadHorse(tiers_id)
					season_mare.forEach(mare => {
						if(mare.horse != undefined && mare.horse.horse_sexe == "F")
						{
							this.list_mare.push(mare.horse)
						}
					})
				}
			}
		},
		computed: {
			avenantsFormatted(){
				let type = []

				for (let i = 0; i < this.avenants.length; i++) {
					let temp = this.avenants[i]

					if(!this.is_cancel)
					{
						if(temp.avenanttype_type != "initial")
						{
							if(!this.syndic_saillie)
							{
								type.push(temp)
							}
							else if(temp.avenanttype_type == "jument" || temp.avenanttype_type == "cmep")
							{
								type.push(temp)
							}
						}
					}

					if(temp.avenanttype_type == "annulation" && this.is_cancel)
					{
						type.push(temp)
					}
				}

				return type 
			}
		},
		watch:{
			season_id(val){
				this.season_id_local = val
			},
			stallion_id(val){
				this.stallion_id_local = val
			},
			contractconfig_id(val){
				this.contractconfig_id_local = val
			},
			avenant_typemonte(val){
				this.avenant_typemonte_local = val
			},
			typemonte(val){
				this.typemonte_local = val
			},
			tiers_tab(val){
				this.tiers_tab_local = val
			},
			tiers(val){
				this.tiers_local = val
				this.loadMare(val.tiers_id)
			},
			avenant(val){
				this.avenant_local = val
			},
			// stallion_model(val){
			// 	this.$emit("update:stallion", val)
			// },
			// stallion(val){
			// 	console.log("modif");
			// },
			// cmep_model(val){
			// 	this.$emit("update:cmep", val)

			// 	if(val != undefined && val != null)
			// 	{
			// 		this.formComplete = true
			// 	}
			// },
			// modele_model(val){
			// 	this.$emit("update:modele", val)
			// },
			// contractconfig_id_local(val){
			// 	this.$emit("update:contractconfig_id", val)
			// },
			// type_monte_model(val){
			// 	this.$emit("update:type_monte", val)

			// 	if(val != undefined && val != null && this.modele_model != undefined && this.modele_model != null && this.stallion_model != undefined && this.stallion_model != null){
			// 		this.formComplete = true
			// 	}
			// }
		},
		components: {
			Saison : () => import('@/components/Contract/Type/Saison'),
			Etalon : () => import('@/components/Contract/Type/Etalon'),
			Modele : () => import('@/components/Contract/Type/Modele'),
			TypeMonte : () => import('@/components/Contract/Type/TypeMonte'),
			TableAvenantArticle : () => import('@/components/Contract/Type/TableAvenantArticle'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert')
		}
	}

</script>